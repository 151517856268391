<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Add Voucher Marketplace
        <div class="card-header-actions">
          <a class="card-header-action" href="voucher-partnership" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
            <div class="sk-three-bounce">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
            </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" novalidate action="https://vuejs.org/">
              <b-form-group id="merchant"
                            label="Merchant Name"
                            label-for="merchant">
                <b-form-input id="merchant"
                              type="text"
                              v-model.lazy.trim="$v.form.merchant.$model"
                              :state="chkState('merchant')"
                              aria-describedby="merchantFeedback"
                              placeholder="Please Enter Merchant Name"
                              autocomplete='given-name'
                              autofocus />
                <b-form-invalid-feedback id="merchantFeedback" v-for="(error , index) in errors.form.merchant" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="name"
                            label="Promotion Name"
                            label-for="name">
                <b-form-input id="name"
                              type="text"
                              v-model.lazy.trim="$v.form.name.$model"
                              :state="chkState('name')"
                              aria-describedby="nameFeedback"
                              placeholder="Please Enter Promotion Name"
                              autocomplete='given-name'
                              autofocus />
                <b-form-invalid-feedback id="nameFeedback" v-for="(error , index) in errors.form.name" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col>
                  <b-form-group id="prefix"
                                label="Promotion ID"
                                label-for="prefix">
                    <b-form-input id="prefix"
                                  type="text"
                                  v-model.lazy.trim="$v.form.prefix.$model"
                                  :state="chkState('prefix')"
                                  onKeyPress="if(this.value.length==5) return false;"
                                  aria-describedby="prefixFeedback"
                                  placeholder="Please Enter Promotion ID"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="prefixFeedback" v-for="(error , index) in errors.form.prefix" :key="index">
                      - {{ error }} <br>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <div>
                    <b-form-group
                      class="mb-3"
                      label="Period Promotion"
                      label-for="daterange"
                    >
                      <b-input-group
                      >
                        <b-input-group-prepend is-text>
                          <i class="fa fa-calendar"></i>
                        </b-input-group-prepend>
                        <date-range-picker id="daterange" :start="startDate" :end="endDate" @picker="changePicker"></date-range-picker>
                      </b-input-group>
                      <div  v-if="this.startDate == '' " >
                        <p id="dateFeedback" v-for="(error , index) in errors.form.start" :key="index">
                          - {{ error }} <br>
                        </p>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-form-group id="subject"
                            label="Subject Email"
                            label-for="subject">
                <b-form-input id="subject"
                  type="text"
                  v-model="form.subject"
                  aria-describedby="subjectFeedback"
                  placeholder="Please Enter Subject Email"
                  autocomplete='given-name'
                  autofocus
                />
              </b-form-group>
              <b-form-group id="body"
                              label="Body Text Email"
                              label-for="body">
                  <quill-editor id="body"
                              style="margin-bottom:5px"
                              v-model="form.body"
                              autocomplete='family-name'>
                  </quill-editor>
              </b-form-group>
              <b-form-group id="title"
                            label="Push Notification Title"
                            label-for="title">
                <b-form-input id="title"
                  type="text"
                  v-model="form.title"
                  aria-describedby="titleFeedback"
                  placeholder="Please Enter Push Notification Title"
                  autocomplete='given-name'
                  autofocus
                />
              </b-form-group>
              <b-form-group id="contentInputGroup2"
                            label="Push Notification Body"
                            label-for="content">
                <b-form-textarea
                  id="textarea-rows"
                  v-model="form.content"
                  placeholder="Enter Content notification ..."
                  aria-describedby="contentFeedback"
                  rows="8"
                ></b-form-textarea>
              </b-form-group>
              <b-form-group label="Status"
                  label-for="status">
                  <b-form-checkbox :plain="true" :value="true" v-model="form.is_active">Active</b-form-checkbox>
              </b-form-group>
              <b-form-group label="Push Information"
                  label-for="basicInlineCheckboxes">
                <b-form-checkbox-group id="basicInlineCheckboxes" name="InlineCheckboxes" :plain="true" v-model="form.notification">
                  <b-form-checkbox :plain="true" value="email">Email</b-form-checkbox>
                  <b-form-checkbox :plain="true" value="push_notification">Push Notification</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, maxLength } from "vuelidate/lib/validators"

export default {
  name: "generate",
  data() {
    return {
      startDate: '',
      endDate: '',
      content: '',
      partner: [],
      organization: [],
      form: {
        name: '',
        content: '',
        merchant: '',
        subject: '',
        prefix: '',
        title: '',
        body: '',
        is_active: false,
        notification: []
      },
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          name: [],
          merchant: [],
          prefix: [],
          start: []
        },
      },
      date: "",
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required
      },
      prefix: {
        required,
        minLength: maxLength(5)
      },
      merchant: {
        required
      }
    }
  },
  methods: {
    changePicker (value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
    },
    onSubmit() {
      this.validate()
      // if (this.validate()) {
      //   this.$nextTick(() => {
        this.isLoading = true
          const formData =  new FormData();

          formData.append("name", this.form.name);
          formData.append("merchant", this.form.merchant);
          formData.append("merchant_prefix", this.form.prefix);
          formData.append("start_from", this.startDate);
          formData.append("end_to", this.endDate);
          formData.append("email_subject", this.form.subject);
          formData.append("email_content", this.form.body);
          formData.append("notification_title", this.form.title);
          formData.append("notification_content", this.form.content);
          formData.append("is_active", this.form.is_active)
          formData.append("notification", this.form.notification)

          this.$http.post(`partnership`, formData)
          .then(() => {
            this.isLoading  = false
            this.$router.push("/voucher-partnership");
            this.$toasted.success('Voucher Marketplace successfully created!')
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
              if(this.errors.status == 422) {
                this.$toasted.error('Please fill out the form that must be required')
                this.errors.message = error.response.data.meta.message;
                this.errors.form.name = this.errors.message.name;
                this.errors.form.merchant = this.errors.message.merchant;
                this.errors.form.prefix = this.errors.message.merchant_prefix;
                this.errors.form.start = this.errors.message.start_from;
              }else if(this.errors.status == 400){
                this.errors.message = error.response.data.meta.message;
                this.$swal.fire(
                  'Failed!',
                  this.errors.message,
                  'error'
                )
              }
            }
          })
      //   })

      // }
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  },
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}


#dateFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}

.invalid-feedback {
    font-size: 100% !important;
}
.notValidate {
  border: #f86c6b 1px solid;
}
.validate {
  border: green 1px solid;
}
#contentFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}

</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
